import React, { useState } from "react"
import Layout from "../../../components/layouts"
import Banner from "../../../components/features/Banner"
import SEO from "../../../components/common/SEO"
import ReactWOW from "react-wow"
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query Audits {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: "Audit Preparation",
    subtitle: `Oof, it’s almost audit season again. There is nothing more anxiety inducing than wondering if you have all your ducks in a row, or whether you have addressed the concerns from your auditor’s last visit. Wouldn’t it be nice if you could ensure that everything was in order, and guarantee that their next visit would go as smoothly as possible? Our Audit Preparation feature can help with that. It allows you to view checklists, self-score, and complete to-dos so that you can rest assured that you’ve crossed all your T’s and dotted your I’s.`,
    // featureName: "safetymeetings",
    rightButtonText: "Overview",
    rightButtonSrc: "",
    mockupImg: '/features/audits/feature-page-header-audit-admin.png'
  }

  const seo = {
    title: "Workhub | Free Audit Standard Software Inspection Preparation Self Audit Checklist",
    description: "Our free online Audit Standard tool helps you ensure you're ready for your next audit inspection by providing checklists, self scores and to do's. Get prepared.",
    image: "/features/audits/social-card.jpg",
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Browse The Library",
      para: "See what your auditor will be looking for by browsing the criteria through Workhub.",
    },
    {
      title: "Prepare Early",
      para: "Ensure you are ready for your next audit by building your documentation ahead of time.",
    },
    {
      title: "Find Issues Early",
      para: "Create Action Items for any missing documentation or processes. Track their progress through the app and be notified of changes.",
    },
    {
      title: "Integrated System",
      para: "All your existing policies, procedures, certifications and more can be tracked seamlessly through Workhub.",
    },
    {
      title: "Archive Documentation",
      para: "Upload your auditor’s results to keep track of your past audits all in one place.",
    },
    {
      title: "Request an Audit Type",
      para: "If we are missing a standard that you need, simply contact us!",
    },
  ]


  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />


      <main className="feature-main">





        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="\features\audits\audits-cartoon.png"
                    alt="Audits Cartoon"
                  /></ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">
                <h2>Prepare with a self-audit.</h2>
                <p>Our tool has over 10 of the latest standards from authorities such as Energy Safety Canada and Work Safe Australia. We can also help you with preparing for your COR (Certificate of Recognition) audit. </p>

                <ul className="checklist">
                  <li>Open the Audit Preparation tool to access the library of available audits and choose one that’s right for you.</li>
                  <li>Collect documentation and link any items you have in other Workhub components that fulfill the audit’s criteria. </li>
                  <li>Create comments and Action Items for any criteria you need to improve upon.</li>
                  <li>Upload your results from your auditor to have all your documentation in one place.</li>
                  <li>Don’t see an audit you need? Just send us the information and we’ll do our best to track it down for you.</li>

                </ul>


              </div>
            </div>
          </div>
        </section>



        {/* FEATURE OVERVIEW */}

        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>

      </main>


    </Layout>
  )
}
